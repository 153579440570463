.media-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-direction: column;
    margin-bottom: 20px;
    /*width: 150%;*/
}
.imgShowMedia{
    width: 400px;
    border-radius: 15px;
}
.imgShowMedia img{
    border-radius: 15px;
    /*border: 1px solid red;*/
}
.audioBoxA{
    /*border: 1px solid yellow;*/
}

.answer-box{
    width: 80%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    z-index: 1;
}

.button-answer{
    width : 40%;
    height: 50px;
    background-color: #fff;
    color: #061849;
    /*padding: 20px;*/
    border-radius: 15px;
    margin: 20px;
    /*height: auto;*/
}

@media screen and (max-width: 767px) {
    .button-answer {
        width: 80%;
        margin: 20px 0px;
    }
}
