/* | ---------------------- | */
/* | - CSS SITUATION - | */
/* | ---------------------- | */
* {
	margin: 0;
	padding: 0;
	box-sizing: 0;
}

body {
	background-color: #061849;
}

/* first div of situation page and simulation page */
.box-situation {
	background-color: #061849;
	/*min-height: calc(100vh - 64px);*/
}


.box_situation {
	/*height: calc(100vh - 64px);*/
	width: 100%;
}

.container_situation {
	width: 85%;
	border-radius: 5px;
}

.box-scroll{
  height: 80vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
}

@media screen and (min-width: 840px) {
	.container_situation {
		width: 30%;
	}
}

/* | ---------------------- | */
/* | - CSS DASHBOARD MENU - | */
/* | ---------------------- | */

.dasboard-menu-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 50px;
}