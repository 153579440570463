.logo-link {
	background: linear-gradient(
		90deg,
		rgba(41, 34, 154, 1) 0%,
		rgba(255, 255, 255, 1) 50%,
		rgba(255, 255, 255, 1) 65%,
		rgba(255, 0, 0, 1) 100%,
		rgba(255, 0, 0, 1)
	);
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
}
