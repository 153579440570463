.formSituation {
	position: absolute;
	z-index: 3;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 10px;
	margin: 10px;
	background: rgba(241, 241, 241, 0.79);
	width: 400px;
	padding: 5px;
}

.labelSituation {
	font-weight: bold;
	font-size: 1rem;
	color: black;
	height: 10px;
}

.labelGroup {
	display: flex;
	flex-direction: column;
	gap: 20px;
	max-height: 60%;
}

.inputSituation {
	border-radius: 5px;
	overflow: auto;
	border: #1a192b 1px solid;
	padding: 5px;
}

.textSituation {
	min-height: 100px;
}


.buttonSituation {
	font-weight: bold;
	font-size: 1rem;
	border-radius: 5px;
	border: green 2px solid;
	background: green;
	color: white;
	width: 50%;
	margin: auto;
	transition: all 400ms;
}

.buttonSituation:hover {
	background: white;
	color: green;
}
