.box_situation{
  background: rgb(6,24,73);
  background: linear-gradient(73deg, rgba(6,24,73,1) 24%, rgba(255,255,255,1) 50%, rgba(239,25,27,1) 72%);
  backdrop-filter: blur(180px);
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: start;
  min-height: calc(100vh - 64px);
}

.link-situation{
  display: block;
  color : #061849;
  font-size: 32px;
  font-weight: 700;
  background-color: #fff;
  border-radius: 15px;
  padding: 15px 50px;
  margin: 0 auto;
  word-wrap: break-word;
  word-break: break-all;
}

@media screen and (max-width: 767px) {
  .link-situation {
    font-size: 15px;
  }
}
