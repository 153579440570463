/* ---------------------------- */
/* - PAGE SituationDetail.tsx - */
/* ---------------------------- */
.box_details {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-wrap: nowrap;
	/*height: 100vh;*/
}

.detail-presentation {
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
	gap: 50px;
	padding: 50px;

	z-index: 2;
}

.box-detail {
	border: 1px solid black;
	padding: 10px;
	text-align: center;
	border-radius: 10px;
	width: 50%;
	color: #061849;
	background: white;
}

.box-title {
	border: 1px solid black;
	padding: 15px;
	border-radius: 8px;
	margin-bottom: 10px;
	font-size: 25px;
}

.box-info {
	border: 1px solid black;
	padding: 15px;
	border-radius: 8px;
	overflow: scroll;
	height: 200px;
	overflow-x: hidden;
	text-align: justify;
}

.box-img {
	border-radius: 15px;
	border: 1px solid red;
}

.button_situation {
	padding: 30px 20px;
	width: 6rem;
	margin: 30px auto;
	color: white;
	font-weight: bolder;
	transition: all 0.25s ease-in-out;
	cursor: pointer;
}

.button-112 {
	margin-top: 40px;
	border-radius: 50%;
	width: 150px;
	cursor: pointer;
	z-index: 3;
}

.box-media{
	display: flex;
	flex-direction: column-reverse;
}
.box-media img{
	border-radius: 15px;
}
.box-media .audioBox audio{
	margin: 20px auto;
}

.box-detail-title {
	padding-bottom: 10px;
	font-weight: bold;
	font-size: 30px;
}

/* -------------- */
/* - RESPONSIVE - */
/* -------------- */

@media screen and (max-width: 980px) {

	.detail-presentation {
		margin-bottom: 40px;
		padding: 10px 0;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		gap: 10px;
	}
	.box-img {
		width: 60%;
		vertical-align: middle;
		align-items: center;
		margin: 0 auto;
	}

	.box-detail {
		width: 90%;
		margin-top: 20px;
	}
	.box-detail-title {
		font-size: 20px;
	}
	.button-112 {
		margin-bottom: 50px;
	}
	.box-details{
		padding: 10px 0;
	}
}