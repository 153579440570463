.panel-container {
	width: 70%;
	padding: 10px;
	background-color: #dadada;
	border-radius: 6px;
}

.panel-action {
	display: flex;
	gap: 15px;
}

.situations-title {
	text-align: center;
	font-size: 45px;
	padding: 15px;
}

.situations-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
}
